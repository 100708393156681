.inputBox {
  position: relative;
  /* width: 250px; */
}

.inputBox input {
  width: 100%;
  padding: 10px 10px 10px 40px; /* Add padding on the left side for the icon */
  border: 1px solid rgba(0, 0, 0, 0.25); /* Border color */
  background: whitesmoke;
  border-radius: 5px;
  outline: none;
  color: black;
  font-size: 1em;
  transition: 0.5s;
  font-family: serif; /* Set font family to serif */
}

.inputBox input::placeholder {
  font-family: serif; /* Set font family to serif for the placeholder text */
  font-weight: bold;
  color: rgba(0, 0, 0, 0); /* Placeholder text color */
}

.inputBox input:valid,
.inputBox input:focus {
  border: 1px solid black; /* Border color when focused or valid */
}

.inputBox .icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the left position to align the icon */
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0.5); /* Icon color */
  font-size: 1.6rem; /* Set icon size */
}

.inputBox span {
  position: absolute;
  left: 40px; /* Position the label to the right of the icon */
  top: 50%;
  transform: translateY(-50%);
  padding: 0 5px;
  pointer-events: none;
  font-size: 1em;
  color: black;
  text-transform: capitalize;
  font-weight: bold;
  transition: 0.5s;
  background: whitesmoke; /* Match the input background */
  font-family: cursive !important;
  letter-spacing: 3px; /* Set font family to serif for the label text */
  font: 600 14px Urbanist, Arial, sans-serif;
}

.inputBox input:focus ~ span,
.inputBox input:valid ~ span {
  top: -2px;
  /* left: 4px; */
  font-size: .80em;
  color: black;
  text-transform: capitalize;
  background: whitesmoke;
  font-family: serif;
  padding: 0.2rem 1rem;
}
