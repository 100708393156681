.walletBalance{
    color: red;
    text-align: center;
    margin: 1rem 0;
}
.walletBox .btn{
    width: 8rem;
}
.walletButton{
    display: flex;
    gap: 2rem;
    margin: 3rem 0rem 1rem 2rem;
    align-items: center !important;
}
.walletButton button{
    width: 200px !important;
}
.tableWithdraw, .tableWithdraw tr, .tableWithdraw th, .tableWithdraw td{
    border: 1px solid black;
    width: 94%;
    table-layout: fixed;
    margin: 2rem;
}