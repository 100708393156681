.containers {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .rows {
    display: flex;
    width: 100%;
  }
  
  .col-md-6 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login3 img {
    width: 85% !important;
    height: 85% !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-radius: 10px;
  }
  
  .login2 {
    width: 80%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 0 !important;
    box-shadow: none;
    margin-left: -18%;
  }
  
  .login {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  .login h1 {
    transform: rotate(360deg);
    text-align: left;
    font-size: 2.8rem;
    font-weight: 700;
    /* margin-top: -13%; */
    margin-bottom: 3%;

  }
  .login h1::before
  {
    content: '';
    content: '';
    position: absolute;
    top: 0%;
    right: 90%;
    width: 19px;
    height: 20px;
    background: #a1cb46;
    border-radius: 50%;
  }
  .login h5 {
    margin-top: -2%;
  }
  
  .login p {
    color: black;
    margin-top: -4%;
  }
  
  .login .form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
  }
  
  .login .btn {
    font-size: 13px;
    width: 95%;
    background-color: #a1cb46;
    border-color: #a1cb46;
    margin-top: 0px;
    color: #000;
  }
  

  .social{
    display: flex;
    gap: 1rem;
  }
  .action{
    display: flex;
    gap: 2rem;
  }
  .forgot-password
  {
    text-decoration: underline !important;
    font-size: 1rem !important;
    line-height: normal !important;
    color: blue !important;
    
  }
  @media (max-width: 768px) {
    .container {
      height: auto; /* Allow container to expand as needed */
      padding: 20px; /* Add padding for better spacing */
    }
  
    .row {
      flex-direction: column; /* Stack columns vertically */
    }
  
    .login3 {
      display: none; /* Hide image container on small screens */
    }
  
    .login2 {
      width: 100%; /* Full width on small screens */
      height: auto; /* Allow height to expand based on content */
      margin-left: 0; /* Remove negative margin */
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Add slight shadow for depth */
      border-radius: 10px; /* Add border radius for rounded corners */
    }
  
    .login {
      padding: 1.5rem; /* Add padding for better spacing */
      width: 100%; /* Full width on small screens */
      max-width: 500px; /* Limit max width for better readability */
      margin: 0 auto; /* Center form container */
    }
  
    .login h1 {
      font-size: 2.2rem; /* Reduce font size for better fit */
    }
  
    .login .btn {
      width: 100%; /* Make button take full width */
    }
    
  .social{
   flex-direction: column;
  }
  }